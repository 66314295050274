<template>
  <v-app>
    <v-app-bar app :color="'#f5f5f5'" v-if="user">
      <v-app-bar-nav-icon
        v-if="isMobile"
        :color="'#0d47a1'"
        @click.stop="$store.commit('setDrawer', !$store.state.drawer)"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu bottom left v-if="user">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" v-ripple>
            <v-row class="ma-0" align="center">
              <v-avatar size="50px">
                <v-img
                  alt="Menu de Usuário"
                  :src="
                    $imgurl(
                      user.img_small || user.img || 'utils/userdefault.png'
                    )
                  "
                ></v-img>
              </v-avatar>
              <v-icon large :color="'#0d47a1'">mdi-chevron-down</v-icon>
            </v-row>
          </div>
        </template>
        <template v-slot:default="menu">
          <v-list>
            <v-dialog width="95vw" max-width="375px">
              <template v-slot:activator="{ on: dialog }">
                <v-list-item v-on="{ ...dialog }">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Meus Dados </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:default="dialog">
                <user-data-form
                  :updatingMyself="true"
                  :data="user"
                  :active="dialog.value"
                  @close="
                    dialog.value = false;
                    menu.value = false;
                    getUser();
                  "
                ></user-data-form>
              </template>
            </v-dialog>
            <v-dialog width="95vw" max-width="375px">
              <template v-slot:activator="{ on: dialog }">
                <v-list-item v-on="{ ...dialog }">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Alterar Senha </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:default="dialog">
                <change-password-form
                  :updatingMyself="true"
                  :data="user"
                  :active="dialog.value"
                  @close="
                    dialog.value = false;
                    menu.value = false;
                    getUser();
                  "
                ></change-password-form>
              </template>
            </v-dialog>

            <v-list-item
              @click="
                logout().then((_) => {
                  menu.value = false;
                })
              "
            >
              <v-progress-circular
                indeterminate
                color="black"
                class="mx-auto"
                v-if="loadingLogout"
              ></v-progress-circular>
              <v-list-item-icon class="mr-2" v-if="!loadingLogout">
                <v-icon color="red">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="!loadingLogout">
                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-app-bar>
    <navigation-drawer v-if="!isLoginRoute"></navigation-drawer>
    <v-main>
      <v-overlay :value="loadingRoute" z-index="99999999">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="ma-0" justify="center" align="center">
          <h5 class="mb-0 mt-2">Carregando</h5>
        </v-row>
      </v-overlay>
      <router-view></router-view>
      <notifications group="app" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    NavigationDrawer: () => import("@components/NavigationDrawerComponent.vue"),
    UserDataForm: () => import("@/pages/Admin/Users/FormData.vue"),
    ChangePasswordForm: () =>
      import("@/pages/Admin/Users/ChangePasswordForm.vue"),
  },
  data() {
    return {
      loading: false,
      loadingLogout: false,
    };
  },
  computed: {
    adminRoute() {
      let regularRoutes = ["/", "/login", "/contact", "/about"];
      if (regularRoutes.includes(this.route)) {
        return false;
      }
      return true;
    },
    route() {
      return this.$route?.path;
    },
    loadingRoute() {
      return this.$store?.state?.loadingRoute;
    },
    isLoginRoute() {
      return this.$route?.path.includes("/login");
    },
    isMobile() {
      return this.$store?.state?.mobile;
    },
    user() {
      return this.$store?.state?.user;
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreenType);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenType);
  },
  mounted() {
    this.checkScreenType();
  },
  methods: {
    checkScreenType() {
      if (window.mobileAndTabletCheck()) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    async getUser() {
      await this.$http
        .get("/auth/me")
        .then((r) => {
          this.$store.commit("setUser", r?.data?.user);
        })
        .catch(() => {
          localStorage.removeItem("tk");
          this.$store.commit("setUser", null);
        });
    },
    async logout() {
      this.loadingLogout = true;
      await this.$http
        .post("/auth/logout")
        .then(() => {
          this.$store.commit("setUser", null);
          localStorage.removeItem("tk");
          this.$resetAxiosConfig();
          setTimeout(() => {
            this.$router.push("/login");
          }, 550);
          this.loadingLogout = false;
        })
        .catch((e) => {
          console.log({ e });
          this.$notify({
            group: "app",
            type: "error",
            title: "Ocorreu um erro",
            text: e?.response?.data?.message || "Tente novamente",
          });
          this.loadingLogout = false;
        });
    },
  },
};
</script>

<style>
</style>