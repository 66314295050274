var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.user)?_c('v-app-bar',{attrs:{"app":"","color":'#f5f5f5'}},[(_vm.isMobile)?_c('v-app-bar-nav-icon',{attrs:{"color":'#0d47a1'},on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('setDrawer', !_vm.$store.state.drawer)}}}):_vm._e(),_c('v-spacer'),(_vm.user)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}]},'div',attrs,false),on),[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-avatar',{attrs:{"size":"50px"}},[_c('v-img',{attrs:{"alt":"Menu de Usuário","src":_vm.$imgurl(
                    _vm.user.img_small || _vm.user.img || 'utils/userdefault.png'
                  )}})],1),_c('v-icon',{attrs:{"large":"","color":'#0d47a1'}},[_vm._v("mdi-chevron-down")])],1)],1)]}},{key:"default",fn:function(menu){return [_c('v-list',[_c('v-dialog',{attrs:{"width":"95vw","max-width":"375px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog }){return [_c('v-list-item',_vm._g({},{ ...dialog }),[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Meus Dados ")])],1)],1)]}},{key:"default",fn:function(dialog){return [_c('user-data-form',{attrs:{"updatingMyself":true,"data":_vm.user,"active":dialog.value},on:{"close":function($event){dialog.value = false;
                  menu.value = false;
                  _vm.getUser();}}})]}}],null,true)}),_c('v-dialog',{attrs:{"width":"95vw","max-width":"375px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog }){return [_c('v-list-item',_vm._g({},{ ...dialog }),[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-lock-reset")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Alterar Senha ")])],1)],1)]}},{key:"default",fn:function(dialog){return [_c('change-password-form',{attrs:{"updatingMyself":true,"data":_vm.user,"active":dialog.value},on:{"close":function($event){dialog.value = false;
                  menu.value = false;
                  _vm.getUser();}}})]}}],null,true)}),_c('v-list-item',{on:{"click":function($event){_vm.logout().then((_) => {
                menu.value = false;
              })}}},[(_vm.loadingLogout)?_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"indeterminate":"","color":"black"}}):_vm._e(),(!_vm.loadingLogout)?_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-logout")])],1):_vm._e(),(!_vm.loadingLogout)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Sair ")])],1):_vm._e()],1)],1)]}}],null,false,535623945)}):_vm._e()],1):_vm._e(),(!_vm.isLoginRoute)?_c('navigation-drawer'):_vm._e(),_c('v-main',[_c('v-overlay',{attrs:{"value":_vm.loadingRoute,"z-index":"99999999"}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center","align":"center"}},[_c('h5',{staticClass:"mb-0 mt-2"},[_vm._v("Carregando")])])],1),_c('router-view'),_c('notifications',{attrs:{"group":"app"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }