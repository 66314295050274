import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import axios from "axios";

const routes = [];
routes.push({
  path: "*",
  component: () => import("./pages/NotFound.vue"),
  name: "Não Encontrado",
  regularRoute: true,
});
routes.push({
  path: "/",
  component: () => import("./pages/Admin/HomeAdmin.vue"),
  name: "Home",
  levels: [1, 2, 3, 4],
});
routes.push({
  path: "/login",
  component: () => import("./pages/LoginPage.vue"),
  name: "Login",
  regularRoute: true,
});
routes.push({
  path: "/users",
  component: () => import("./pages/Admin/Users/ListData.vue"),
  name: "Usuários",
  levels: [1, 2, 3],
});
routes.push({
  path: "/ceps",
  component: () => import("./pages/Admin/Ceps/ListData.vue"),
  name: "Ceps",
  levels: [1, 2, 3],
});
routes.push({
  path: "/gateways",
  component: () => import("./pages/Admin/Gateways/ListData.vue"),
  name: "Gateways",
  levels: [1],
});
routes.push({
  path: "/news",
  component: () => import("./pages/Admin/News/ListData.vue"),
  name: "Notícias",
  levels: [1, 2, 3],
});
routes.push({
  path: "/partners",
  component: () => import("./pages/Admin/Partners/ListData.vue"),
  name: "Parceiros",
  levels: [1, 2],
});
routes.push({
  path: "/associations",
  component: () => import("./pages/Admin/Associations/ListData.vue"),
  name: "Associações",
  levels: [1, 2],
});
routes.push({
  path: "/scholar-levels",
  component: () => import("./pages/Admin/ScholarLevels/ListData.vue"),
  name: "Níveis Escolares",
  levels: [1, 2],
});
routes.push({
  path: "/coupons",
  component: () => import("./pages/Admin/Coupons/ListData.vue"),
  name: "Cupons",
  levels: [1, 2],
});
routes.push({
  path: "/notifications",
  component: () => import("./pages/Admin/Notifications/ListData.vue"),
  name: "Notificações",
  levels: [1, 2],
});
routes.push({
  path: "/student-card-layouts",
  component: () => import("./pages/Admin/StudentCardLayouts/ListData.vue"),
  name: "Layouts Carteirinhas",
  levels: [1, 2],
});
routes.push({
  path: "/student-cards",
  component: () => import("./pages/Admin/StudentCards/ListData.vue"),
  name: "Carteirinhas",
  levels: [1, 2, 3],
});
routes.push({
  path: "/configs",
  component: () => import("./pages/Admin/Configs/ListData.vue"),
  name: "Configurações Sistema",
  levels: [1],
});
routes.push({
  path: "/reports",
  component: () => import("./pages/Admin/Reports/ReportsList.vue"),
  name: "Relatórios",
  levels: [1, 2],
});
routes.push({
  path: "/pay/:id",
  component: () => import("./pages/QrCodePage.vue"),
  name: "QRCode Carteirinha",
  regularRoute: true,
});

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

async function getUser() {
  store.commit("setLoadingRoute", true);
  await axios
    .get("/auth/me")
    .then((r) => {
      store.commit("setLoadingRoute", false);
      store.commit("setUser", r?.data?.user);
    })
    .catch(() => {
      store.commit("setLoadingRoute", false);
      localStorage.removeItem("tk");
      store.commit("setUser", null);
    });
}
function afterEach(to) {
  Vue.nextTick(() => {
    let pageTitle = to.name;
    /** Ao navegar por quaisquer das subrotas do evento, atualizar-se-á o título da página */
    if (to?.params?.eventId && store.state.eventSelected) {
      pageTitle = `${pageTitle} | ${store.state.eventSelected?.name}`;
    }
    document.title = `ABE® | ${pageTitle}`;
  });
}

function resetAxiosConfig() {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("tk")}`,
  };
}

router.beforeEach(async (to, from, next) => {
  let path = to.path;
  if (path.includes("/loginforced")) {
    let token = to.path.replace("/loginforced/", "");
    localStorage.setItem("tk", token);
    resetAxiosConfig();
    window.location.href = "/";
    return;
  }
  let matched = to.matched;
  if (matched.length == 0) {
    return next("/not-found");
  }
  let found = routes?.find((r) => r.name == matched[0].name);
  if (!found) {
    if (path == "/not-found") return next();
    return next("/not-found");
  }
  let user = store.state.user;
  if (!user) {
    await getUser();
    user = store.state.user;
  }

  if (found?.regularRoute == true) {
    return next();
  }

  if (!user && path != "/login") {
    return next("/login");
  }
  if (user && path == "/login") {
    return next(from.path);
  }

  if (user && !found?.levels?.includes(user.level)) {
    return next("/not-found");
  }

  return next();
});
router.afterEach((to) => {
  store.commit("setPath", to.path);
  afterEach(to);
});
export default router;
